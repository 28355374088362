<mat-dialog-content class="dialog-container">
    <div class="wp-row">
        <div>
            Images for <b>{{ data.name }}</b>
        </div>
        <div style="flex: 1"></div>
        <button mat-button mat-dialog-close>Close</button>
        <input
            type="file"
            #fileInput
            id="file-input"
            style="display: none"
            [accept]="acceptedExtensions"
            multiple
            (change)="onFilesSelectedForUpload($event)"
            [disabled]="areImagesUploading$ | async"
        />
        <button *ngIf="(view$ | async) === 'My Images'" mat-button (click)="fileInput.click()" [disabled]="areImagesUploading$ | async">
            Upload New Image
        </button>
        <mat-spinner *ngIf="areImagesUploading$ | async" [diameter]="24"></mat-spinner>
    </div>

    <mat-button-toggle-group
        *ngIf="data.mlsListing"
        class="wp"
        [value]="view$ | async"
        (valueChange)="view$.next($event)"
        [hideSingleSelectionIndicator]="true"
    >
        <mat-button-toggle [checked]="(view$ | async) === 'MLS Images'" value="MLS Images"> MLS Images </mat-button-toggle>
        <mat-button-toggle [checked]="(view$ | async) === 'My Images'" value="My Images"> My Images </mat-button-toggle>
    </mat-button-toggle-group>
    <ng-container *ngIf="imageGalleryImages$ | async as images">
        <div *ngIf="images.length > 0" style="margin-top: 16px">Select an image below</div>
    </ng-container>

    <!-- My Images  -->
    <div class="images-placeholder" *ngIf="(view$ | async) === 'My Images'">
        <button
            mat-button
            *ngFor="let image of imageGalleryImages$ | async; trackBy: trackByImagePickerItem"
            style="padding: 0"
            [disableRipple]="true"
            [ngStyle]="{
                width: '162px',
                height: '162px',
            }"
        >
            <img
                [src]="image.image.get('thumbURL') || image.image.get('url')"
                loading="lazy"
                style="width: 162px; height: 162px; object-fit: cover; border: 1px solid white; border-radius: 4px"
                (click)="onImageClicked(image)"
            />
            <div style="position: absolute; top: 0; right: 0">
                <app-chili-image-delete-button [imageItem]="image" (imageDeleted)="imageDeleted$.next(image)"> </app-chili-image-delete-button>
            </div>
        </button>

        <!-- Loading Skeletons for New Images -->
        <div *ngIf="(isLoadingImages$ | async) === true && (view$ | async) === 'My Images'" class="skeleton-placeholder">
            <ngx-skeleton-loader
                *ngFor="let skeletonLoaderPlaceholder of skeletonLoaderPlaceholders"
                animation="pulse"
                appearance="line"
                [theme]="{ height: '162px', width: '161px' }"
                style="height: 162px"
            >
            </ngx-skeleton-loader>
        </div>

        <ng-container *ngIf="imageGalleryImages$ | async as images">
            <div *ngIf="images.length === 0 && (view$ | async) === 'My Images'" style="display: flex; justify-content: center; width: 100%">
                Click
                <div style="color: #4d1646; font-weight: 600; margin: 0 4px; cursor: pointer" (click)="fileInput.click()">here</div>
                to upload your first image
            </div>
        </ng-container>
    </div>
    <div #loadTrigger class="load-trigger"></div>

    <!-- MLS Images -->
    <div class="images-placeholder" *ngIf="(view$ | async) === 'MLS Images'">
        <button
            mat-button
            *ngFor="let image of mlsImages$ | async; trackBy: trackByImagePickerItem"
            style="padding: 0; width: 324px; height: 216px"
            [disableRipple]="true"
        >
            <ng-container *ngIf="image.type === 'MLS LISTING'">
                <img
                    *ngIf="image.src$ | async as src"
                    [src]="src"
                    loading="lazy"
                    style="width: 324px; height: 216px; object-fit: cover; border: 1px solid white; border-radius: 4px"
                    (click)="onImageClicked(image)"
                />

                <ngx-skeleton-loader
                    *ngIf="(image.src$ | async) === ''"
                    animation="pulse"
                    appearance="line"
                    [theme]="{ height: '216px', width: '323px' }"
                    style="height: 216px; margin-left: 1px"
                >
                </ngx-skeleton-loader>
            </ng-container>
        </button>
    </div>
</mat-dialog-content>
