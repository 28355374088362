import { AsyncPipe, NgFor, NgIf, NgStyle } from '@angular/common'
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Inject, OnDestroy, Output, ViewChild } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MatButtonToggleModule } from '@angular/material/button-toggle'
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar'
import { DomSanitizer, SafeUrl } from '@angular/platform-browser'
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader'
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs'
import { debounceTime, map, scan, shareReplay, startWith, switchMap, takeUntil, tap } from 'rxjs/operators'
import { UploadFileService } from 'src/app/admin/upload-file.service'
import { ParseService } from 'src/app/parse.service'
import { Objects } from '../../../../../server/src/shared-with-client/object-definitions'
import { ChiliImageDeleteButtonModule } from '../chili-image-delete-button/chili-image-delete-button.module'
import { acceptedExtensionsArray } from '../utils/constants'

export interface ImagePickerData {
    name: string
    establishmentId: string
    category: Objects.Image.Category | undefined
    subcategory: string | undefined
    mlsListing: Objects.Mls.Listing | undefined
    photoKey: 'photoKey' | 'mlsNumber' | undefined
}

export interface MlsListingImageItem {
    id: string
    type: 'MLS LISTING'
    url: string
}

type GalleryMlsListingImageItem = MlsListingImageItem & { src$: BehaviorSubject<SafeUrl> }

interface ImageGalleryImageItem {
    id: string
    type: 'IMAGE GALLERY'
    image: Objects.Image.Parse
    rekognition?: AWS.Rekognition.DetectFacesResponse
}

export type ImagePickerItem = ImageGalleryImageItem | GalleryMlsListingImageItem

@Component({
    selector: 'app-image-picker',
    templateUrl: './image-picker-dialog.component.html',
    styleUrls: ['./image-picker-dialog.component.scss'],
    providers: [UploadFileService],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        AsyncPipe,
        ChiliImageDeleteButtonModule,
        MatDialogModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatSnackBarModule,
        MatProgressSpinnerModule,
        NgFor,
        NgIf,
        NgStyle,
        NgxSkeletonLoaderModule,
    ],
})
export class ImagePickerDialogComponent implements AfterViewInit, OnDestroy {
    @ViewChild('loadTrigger') loadTrigger?: ElementRef

    @Output() imageSelected = new EventEmitter<
        { image: Objects.Image.Parse; rekognition: AWS.Rekognition.DetectFacesResponse | undefined } | { image: MlsListingImageItem; rekognition: undefined }
    >()

    readonly acceptedExtensions = acceptedExtensionsArray.join(',')
    private intersectionObserver?: IntersectionObserver

    loadMore$ = new Subject<void>()
    imageDeleted$ = new Subject<ImageGalleryImageItem>()
    imageAdded$ = new Subject<ImageGalleryImageItem>()
    isLoadingImages$ = new BehaviorSubject(false)
    areImagesUploading$ = new BehaviorSubject(false)
    view$ = new BehaviorSubject<'MLS Images' | 'My Images'>('My Images')

    private hasMoreImages = false

    skeletonLoaderPlaceholders = new Array(12)
    private currentImageGallerySkip = 0

    destroy$ = new Subject<void>()

    mlsImages$: Observable<GalleryMlsListingImageItem[] | undefined> = combineLatest([this.parseService.selectedEstablishment$, this.view$]).pipe(
        tap(() => {
            this.isLoadingImages$.next(true)
        }),
        switchMap(async ([selectedEstablishment, view]) => {
            try {
                if (view === 'MLS Images') {
                    this.hasMoreImages = false
                    return this.loadMlsImages(this.data)
                }
            } catch (err) {
                this.parseService.reportUIError(err, 'ImagePickerComponent.images$.loadImages', JSON.stringify({ establishmentID: selectedEstablishment.id }))
                return undefined
            }
        }),
        tap(() => {
            this.isLoadingImages$.next(false)
        }),
        shareReplay({ refCount: true, bufferSize: 1 })
    )

    deletedImageGalleryImages$ = this.imageDeleted$.pipe(
        startWith(undefined),
        scan<ImageGalleryImageItem | undefined, ImageGalleryImageItem[]>((acc, image) => {
            if (!image) {
                return acc
            }
            return [...acc, image]
        }, []),
        takeUntil(this.destroy$),
        shareReplay(1)
    )

    addedImageGalleryImages$ = this.imageAdded$.pipe(
        startWith(undefined),
        scan<ImageGalleryImageItem | undefined, ImageGalleryImageItem[]>((acc, image) => {
            if (!image) {
                return acc
            }
            return [...acc, image]
        }, []),
        takeUntil(this.destroy$),
        shareReplay(1)
    )

    imageGalleryImages$: Observable<ImageGalleryImageItem[]> = combineLatest([
        this.parseService.selectedEstablishment$,
        this.view$,
        this.loadMore$.pipe(debounceTime(200), startWith(undefined)),
    ]).pipe(
        tap(() => {
            this.isLoadingImages$.next(true)
        }),
        switchMap(async ([selectedEstablishment, view]) => {
            try {
                if (view === 'My Images') {
                    const defaultLimit = 12
                    const currentSkip = this.currentImageGallerySkip

                    const imagesNeededToCompleteBatch = defaultLimit - (currentSkip % defaultLimit)
                    const limit = defaultLimit + imagesNeededToCompleteBatch

                    const images = await this.parseService.loadImages(selectedEstablishment.id, undefined, undefined, false, currentSkip, limit)

                    this.hasMoreImages = images.length === limit

                    const returnValue: {
                        imagePickerItems: ImageGalleryImageItem[]
                    } = {
                        imagePickerItems: images.map((image) => ({
                            id: image.id,
                            image,
                            type: 'IMAGE GALLERY',
                            rekognition: undefined,
                        })),
                    }

                    return returnValue
                }
                return undefined
            } catch (err) {
                this.parseService.reportUIError(err, 'ImagePickerComponent.images$.loadImages', JSON.stringify({ establishmentID: selectedEstablishment.id }))
                return undefined
            }
        }),
        tap(() => {
            this.isLoadingImages$.next(false)
        }),
        // Accumulate images with scan to support "load more" functionality
        scan((acc: ImageGalleryImageItem[], curr) => {
            if (!curr) return acc
            // Accumulate images with previously loaded images
            return [...acc, ...curr.imagePickerItems]
        }, []),
        // Combine accumulated images with deleted and added IDs for filtering
        switchMap((images) =>
            combineLatest([of(images), this.deletedImageGalleryImages$, this.addedImageGalleryImages$]).pipe(
                map(([loadedImages, deletedImageGalleryImages, addedImageGalleryImages]) => {
                    return [...addedImageGalleryImages, ...loadedImages].filter((image, i, all) => {
                        const isDuplicate =
                            all.findIndex((el) => {
                                return el.id === image.id
                            }) !== i

                        if (isDuplicate) {
                            return false
                        }
                        const isDeleted =
                            deletedImageGalleryImages.find((el) => {
                                return el.id === image.id
                            }) !== undefined

                        return !isDeleted
                    })
                })
            )
        ),
        tap((images) => {
            this.currentImageGallerySkip = images.length
        }),
        shareReplay({ refCount: true, bufferSize: 1 })
    )

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: ImagePickerData,
        private parseService: ParseService,
        private uploadFileService: UploadFileService,
        private snackbar: MatSnackBar,
        private dialogRef: MatDialogRef<unknown>,
        private sanitizer: DomSanitizer
    ) {
        if (data.mlsListing) {
            this.view$.next('MLS Images')
        }
    }

    ngAfterViewInit() {
        this.setupIntersectionObserver()
    }

    ngOnDestroy() {
        if (this.intersectionObserver) {
            this.intersectionObserver.disconnect()
        }
        this.destroy$.next()
    }

    loadMlsImages(mslListingData: ImagePickerData): GalleryMlsListingImageItem[] {
        const mlsListingImageItems: GalleryMlsListingImageItem[] = []
        const photosCount = mslListingData?.mlsListing?.photosCount
        const photoURLPrefix = mslListingData?.mlsListing?.photoURLPrefix
        if (photosCount && photoURLPrefix && mslListingData?.photoKey && mslListingData?.mlsListing && [mslListingData?.photoKey]) {
            for (let i = 0; i < photosCount; i++) {
                const url = `${photoURLPrefix}${mslListingData?.mlsListing[mslListingData?.photoKey]}/photo_${i + 1}.jpg`

                const img: GalleryMlsListingImageItem = {
                    id: mslListingData?.mlsListing._id + '-' + (i + 1),
                    type: 'MLS LISTING',
                    url,
                    src$: new BehaviorSubject<SafeUrl>(''),
                }

                mlsListingImageItems.push(img)

                setTimeout(() => {
                    const headers = new Headers()
                    headers.set('X-Requested-With', 'XMLHttpRequest')

                    fetch(`https://proxy-production-249cd0758748.herokuapp.com/${url}`, { headers })
                        .then((r) => {
                            return r.blob()
                        })
                        .then((blob) => {
                            const url = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob))
                            img.src$.next(url)
                        })
                        .catch((err) => {
                            console.error(err)
                        })
                }, i * 200)
            }
        }
        return mlsListingImageItems
    }

    private setupIntersectionObserver() {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.1,
        }

        this.intersectionObserver = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting && !this.isLoadingImages$.value && this.hasMoreImages) {
                    this.loadMore$.next()
                }
            })
        }, options)

        if (this.loadTrigger && this.loadTrigger.nativeElement) {
            this.intersectionObserver.observe(this.loadTrigger.nativeElement)
        }
    }

    async onFilesSelectedForUpload(ev: HTMLElementEventMap['change']) {
        try {
            const target = ev.target as HTMLInputElement | undefined
            if (!target?.files) {
                return
            }

            const fileList = target.files
            const { establishmentId, category, subcategory } = this.data
            const imagesToCreate: File[] = []
            for (let i = 0; i < fileList.length; i++) {
                const file = fileList.item(i)
                if (file) {
                    const maxFileSize: Objects.Image.MaxImageSize = 83886080
                    if (file.size < maxFileSize) {
                        imagesToCreate.push(file)
                    } else {
                        this.snackbar.open('The Image Is Too Large. Please Upload Images Under 80MB', undefined, { duration: 5000, panelClass: ['error'] })
                    }
                }
            }

            try {
                for (const file of imagesToCreate) {
                    if (!acceptedExtensionsArray.some((x: string) => file.name.toLowerCase().endsWith(x))) {
                        throw new Error(`Please Upload Images With One Of The Following File Extensions: ${acceptedExtensionsArray.join(', ')}`)
                    }
                }
            } catch (err) {
                this.snackbar.open(err.message, undefined, { duration: 5000, panelClass: ['error'] })
                return
            }

            this.areImagesUploading$.next(true)

            for (const file of imagesToCreate) {
                try {
                    const imageCreateResponse = await this.parseService.createImage(establishmentId, file.name, category, subcategory)
                    await this.uploadFileService.uploadfile(imageCreateResponse.signedPutURL, { blob: file })
                    const imageID = imageCreateResponse.image.id
                    const job = await this.parseService.processImage(establishmentId, imageID)
                    await this.parseService.awaitJobCompletion(job, 500)
                    const updatedImage = await this.parseService.getImage(imageID)

                    if (updatedImage) {
                        const imagePickerItem: ImageGalleryImageItem = {
                            id: updatedImage.id,
                            image: updatedImage,
                            type: 'IMAGE GALLERY',
                            rekognition: undefined,
                        }
                        this.imageAdded$.next(imagePickerItem)
                    }
                } catch (err) {
                    const errorMessagesMap: {
                        [key: string]: string
                    } = {
                        'Request failed with status code 500 - Preview generation failed':
                            'The uploaded file may be incompatible with the editor. Please reach out to customer support to upload it for you.',
                    }

                    let snackbarMessage = err.message
                    if (Object.prototype.hasOwnProperty.call(errorMessagesMap, err.message)) {
                        snackbarMessage = errorMessagesMap[err.message]
                    }
                    this.snackbar.open(snackbarMessage, undefined, { duration: 7500, panelClass: ['error'] })
                    this.parseService.reportUIError(err, 'ImagePicker.onFilesSelected', JSON.stringify({ fileName: file.name, size: file.size }))
                }
            }
        } catch (err) {
            this.parseService.reportUIError(err, 'ImagePicker.onFilesSelected', '')
        }
        this.areImagesUploading$.next(false)
    }

    async onImageClicked(imagePickerItem: ImagePickerItem) {
        try {
            if (imagePickerItem.type === 'IMAGE GALLERY') {
                const rekognitionFacialDetection = imagePickerItem.image.get('rekognitionFacialDetection')
                if (!imagePickerItem.rekognition && rekognitionFacialDetection) {
                    const rekognitionParseObject = await this.parseService.getRekognitionFacialDetection(rekognitionFacialDetection.id)
                    const rekognition = rekognitionParseObject?.get('fullResponse')
                    if (rekognition) {
                        imagePickerItem.rekognition = rekognition
                    }
                }

                this.imageSelected.emit({ image: imagePickerItem.image, rekognition: imagePickerItem.rekognition })
            } else {
                this.imageSelected.emit({ image: imagePickerItem, rekognition: undefined })
            }
        } catch (err) {
            this.parseService.reportUIError(err, 'Shared.ImagePicker.onImageClicked', JSON.stringify({ imagePickerItem }))
        }

        this.dialogRef.close()
    }

    trackByImagePickerItem(index: number, obj: ImagePickerItem) {
        return obj.id
    }
}
